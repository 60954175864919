// require("webpack")
require("bootstrap")
require("jquery")
require("popper.js")

console.log('tooltip.js loaded')

function init() {
	$('[data-toggle="tooltip"]').tooltip()
}

$(document).on("turbolinks:load", function() {
	$(function () {
		init()
	})
});
